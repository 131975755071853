import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import { LanguageProvider } from 'containers/LanguageProvider';
import configureStore from 'store/config/configureStore';
import { translationMessages } from 'i18n';
import Root from 'containers/Root';

// Create redux store with history
const initialState = {};

function App() {
  const store = configureStore(initialState, history);
  return (
    <Provider store={store}>
      <LanguageProvider messages={translationMessages} locale="en">
        <ConnectedRouter history={history}>
          <Root history={history} />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  );
}

export default App;
