/**
 * Devices action types
 */

export const FETCH_DEVICES_BY_SITE_ID_START = '[Devices] FETCH_DEVICES_BY_SITE_ID_START';
export const FETCH_DEVICES_BY_SITE_ID_FAIL = '[Devices] FETCH_DEVICES_BY_ID_FAIL';
export const SET_DEVICES = '[Devices] SET_DEVICES';
export const SET_DEVICE_GROUPS = '[Devices] SET_DEVICE_GROUPS';

export const SET_DEVICES_FILTERS = '[Devices] SET_DEVICES_FILTERS';

export const SET_DEVICE_STATUS = '[Devices] SET_DEVICE_STATUS';
export const SET_NEW_DEVICE_STATUS = '[Devices] SET_NEW_DEVICE_STATUS';
export const FETCH_DEVICE_STATUS_FAIL = '[Devices] FETCH_DEVICE_STATUS_FAIL';
export const FETCH_DEVICE_STATUS_START = '[Devices] FETCH_DEVICE_STATUS_START';

export const FETCH_DEVICE_TELEMETRY_START = '[Devices] FETCH_DEVICE_TELEMETRY_START';
export const SET_DEVICE_TELEMETRY = '[Devices] SET_DEVICE_TELEMETRY';
export const FETCH_DEVICE_TELEMETRY_FAIL = '[Devices] FETCH_DEVICE_TELEMETRY_FAIL';

export const FETCH_TELEMETRY_BY_DEVICE_TYPE_START =
  '[Devices] FETCH_TELEMETRY_BY_DEVICE_TYPE_START';
export const SET_TELEMETRY_BY_DEVICE_TYPE = '[Devices] SET_TELEMETRY_BY_DEVICE_TYPE';
export const FETCH_TELEMETRY_BY_DEVICE_TYPE_FAIL = '[Devices] FETCH_TELEMETRY_BY_DEVICE_TYPE_FAIL';

export const FETCH_DEVICE_CONFIG_START = '[Devices] FETCH_DEVICE_CONFIG_START';
export const SET_DEVICE_CONFIG = '[Devices] SET_DEVICE_CONFIG';
export const FETCH_DEVICE_CONFIG_FAIL = '[Devices] FETCH_DEVICE_CONFIG_FAIL';

export const UPDATE_DEVICE_STATUS_START = '[Devices] UPDATE_DEVICE_STATUS_START';
export const UPDATE_DEVICE_STATUS_FAIL = '[Devices] UPDATE_DEVICE_STATUS_FAIL';
export const CLEAR_DEVICE_UPDATED = '[Devices] CLEAR_DEVICE_UPDATED';

export const UPDATE_DEVICE_TABS_DISABLED = '[Devices] UPDATE_DEVICE_TABS_DISABLED';

export const GET_EVENTS_EXCEL_DOWNLOAD_START = '[Devices] GET_EVENTS_EXCEL_DOWNLOAD_START';
export const SET_EVENTS_EXCEL_DOWNLOAD = '[Devices] SET_EVENTS_EXCEL_DOWNLOAD';
export const GET_EVENTS_EXCEL_DOWNLOAD_FAIL = '[Devices] GET_EVENTS_EXCEL_DOWNLOAD_FAIL';
export const CLEAR_EVENTS_EXCEL_DOWNLOAD = '[Devices] CLEAR_EVENTS_EXCEL_DOWNLOAD';

export const FETCH_DEVICE_EVENTS_START = '[Devices] FETCH_DEVICE_EVENTS_START';
export const SET_DEVICE_EVENTS = '[Devices] SET_DEVICE_EVENTS';
export const FETCH_DEVICE_EVENTS_FAIL = '[Devices] FETCH_DEVICE_EVENTS_FAIL';
