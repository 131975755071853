const envVars = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authAuthority: process.env.REACT_APP_AUTH_AUTHORITY,
  authRedirectURI: `${window.location.origin}${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
  authBaseUrl: process.env.REACT_APP_AUTH_BASE_URL,
  authPostLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  domainName: process.env.REACT_APP_DOMAIN_NAME,
  doLog: process.env.REACT_APP_DO_LOG === 'true',
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  privacyAndPolicyUri: process.env.REACT_APP_PRIVACY_AND_POLICY_URI,
  routes: {
    sitesService: process.env.REACT_APP_ROUTES_SITES_SERVICE,
    usersService: process.env.REACT_APP_ROUTES_USERS_SERVICE,
    notificationsService: process.env.REACT_APP_ROUTES_NOTIFICATIONS_SERVICE,
    chartsService: process.env.REACT_APP_ROUTES_CHARTS_SERVICE,
    devicesService: process.env.REACT_APP_ROUTES_DEVICES_SERVICE,
    salesforceService: process.env.REACT_APP_ROUTES_SALESFORCE_SERVICE,
  },
  idp: {
    type: process.env.REACT_APP_IDP_TYPE,
    id: process.env.REACT_APP_IDP_ID,
  },
};

export default envVars;
