/**
 * Settings constants
 */
export const FETCH_NOTIFICATIONS_SETTINGS_START = '[Settings] FETCH_NOTIFICATIONS_SETTINGS_START';
export const FETCH_NOTIFICATIONS_SETTINGS_FAIL = '[Settings] FETCH_NOTIFICATIONS_SETTINGS_FAIL';
export const SET_NOTIFICATIONS_SETTINGS = '[Settings] SET_NOTIFICATIONS_SETTINGS';
export const SET_NOTIFICATION_PREFERENCES = '[Settings] SET_NOTIFICATION_PREFERENCES';
export const SET_NOTIFICATION_PREFERENCES_START = '[Settings] SET_NOTIFICATION_PREFERENCES_START';
export const SET_NOTIFICATION_PREFERENCES_FAIL = '[Settings SET_NOTIFICATION_PREFERENCES_FAIL';
export const SET_UNIT_MEASUREMENT_START = '[Settings] SET_UNIT_MEASUREMENT_START';
export const SET_UNIT_MEASUREMENT = '[Settings] SET_UNIT_MEASUREMENT';
export const SET_UNIT_MEASUREMENT_FAIL = '[Settings] SET_UNIT_MEASUREMENT_FAIL';
export const FETCH_SITE_NOTIFICATIONS_START = '[Settings] FETCH_SITE_NOTIFICATIONS_START';
export const SET_SITE_NOTIFICATIONS = '[Settings] SET_SITE_NOTIFICATIONS';
export const PATCH_SITE_NOTIFICATIONS = '[Settings] PATCH_SITE_NOTIFICATIONS';
export const PATCH_SITE_NOTIFICATIONS_FAIL = '[Settings] PATCH_SITE_NOTIFICATIONS_FAIL';
export const FETCH_SITE_NOTIFICATIONS_FAIL = '[Settings] FETCH_SITE_NOTIFICATIONS_FAIL';
