/*
 * Login Component
 *
 * This contains Login
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { postUserSessionLoginEvent } from 'store/User/actions';
import { connect } from 'react-redux';
import OktaSignInWidget from './OktaSignInWidget';

/**
 * Login
 * @param {*} config Okta Widget configuration
 */
function OktaLogin(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const { userSessionEventDispatch, config } = props;
  let loginComponent = null;

  useEffect(() => {
    document.body.classList.add('login');

    return () => {
      document.body.classList.remove('login');
    };
  }, []);

  /**
   * @method handleOktaSuccess
   * Handles success from okta sign in widget callback.
   * and Redirect the user to the dashboard.
   * @param tokens The tokens received from okta to authorize the user.
   */
  const handleOktaSuccess = (tokens) => {
    if (oktaAuth) {
      oktaAuth.handleLoginRedirect(tokens)?.then(() => {
        userSessionEventDispatch();
      });
    }
  };

  if (authState && !authState.isPending) {
    loginComponent = authState.isAuthenticated ? (
      <Redirect to={{ pathname: '/' }} />
    ) : (
      <OktaSignInWidget
        data-testid="oktaSignInWidget"
        config={config}
        onSuccess={handleOktaSuccess}
      />
    );
  }

  return loginComponent;
}

OktaLogin.propTypes = {
  config: PropTypes.object,
  userSessionEventDispatch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  userSessionEventDispatch: () => dispatch(postUserSessionLoginEvent()),
});

export default connect(null, mapDispatchToProps)(OktaLogin);
