/**
 * App reducer
 */
import {
  HIDE_LOADER,
  HIDE_TOAST,
  SHOW_LOADER,
  SHOW_TOAST,
  SHOW_API_ERROR,
  HIDE_API_ERROR,
} from './types';

const initialState = {
  loading: false,
  toast: {
    showToast: false,
    event: 'success',
  },
  requestQueueCounter: 0,
  apiFailureMessage: {
    showBanner: false,
    type: '',
    code: '',
  },
};

/**
 * appReducer
 * Reducer method to apply state to selected action
 * @param {object} state current redux state for application
 * @param {object} action action creator object
 * @return {Object}
 */
const appReducer = (state = initialState, action = {}) => {
  // Doing in this way since null value is a valid value and
  // it doesn't apply a default initial state if we do it on the input params
  let tempState = state || initialState;

  switch (action.type) {
    case SHOW_LOADER:
      tempState = {
        ...state,
        loading: true,
        requestQueueCounter: state.requestQueueCounter + 1,
      };
      break;
    case HIDE_LOADER:
      tempState = {
        ...state,
        loading: state.requestQueueCounter - 1 > 0,
        requestQueueCounter: state.requestQueueCounter - 1,
      };
      break;
    case SHOW_TOAST:
      tempState = {
        ...state,
        toast: {
          event: action.payload,
          showToast: true,
        },
      };
      break;
    case HIDE_TOAST:
      tempState = {
        ...state,
        toast: {
          ...state.toast,
          showToast: false,
        },
      };
      break;
    case SHOW_API_ERROR:
      tempState = {
        ...state,
        apiFailureMessage: {
          showBanner: true,
          type: action.payload.type,
          code: action.payload.code,
        },
      };
      break;
    case HIDE_API_ERROR:
      tempState = {
        ...state,
        apiFailureMessage: {
          showBanner: false,
          type: action.payload.type,
          code: action.payload.code,
        },
      };
      break;
    default:
      tempState = { ...tempState };
      break;
  }

  return tempState;
};

export default appReducer;
