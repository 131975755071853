import React, { useState, useCallback } from 'react';
import { pdfjs } from 'react-pdf';
import { FullWidthHeightDiv, StyledDocument, StyledPage } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

function TermsAndConditionsPage() {
  const pdfUrl = 'Terms_and_Conditions_11-13-2024.pdf';
  const scale = 2.0;
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = useCallback(({ numPages: loadedNumPages }) => {
    setNumPages(loadedNumPages);
  }, []);

  const renderPages = () => {
    let pages = [];
    if (numPages) {
      for (let i = 1; i <= numPages; i += 1) {
        pages.push(<StyledPage key={`page_${i}`} pageNumber={i} scale={scale} />);
      }
    } else {
      pages = <p>Loading PDF...</p>;
    }
    return pages;
  };

  return (
    <FullWidthHeightDiv>
      <StyledDocument file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {renderPages()}
      </StyledDocument>
    </FullWidthHeightDiv>
  );
}

export default TermsAndConditionsPage;
