import apiRequest from 'store/api/actions';
import { doDelete, doGet, doPost, doPut } from 'system/httpHelper';
import { endpointFormatter } from 'utils/endpoint';
import { getValueIfExists } from 'utils/propertyValidation';
import envVars from 'env/config';
import bannerHide from 'utils/enums/bannerHide';
import {
  FETCH_USER_POLICY,
  FETCH_USER_POLICY_FAIL,
  FETCH_USER_POLICY_START,
  SET_USER_POLICY,
  SET_USER_ROLE_CONFIG,
  SET_USER_ROLE_CONFIG_FAIL,
  SET_USER_APP_CONFIG,
  STORE_USER,
  UPDATE_TERMS_AND_CONDITIONS,
  UPDATE_TERMS_AND_CONDITIONS_FAIL,
  UPDATE_USER_PROFILE_OKTA,
  UPDATE_USER_PROFILE_OKTA_FAIL,
  UPDATE_USER_PASSWORD_OKTA,
  CLEAN_OKTA_MESSAGES_PASSWORD,
  SET_USER_PERMISSIONS,
  UPDATE_BROADCAST_MESSAGE_START,
  UPDATE_BROADCAST_MESSAGE_FAIL,
  UPDATE_BROADCAST_MESSAGE_SUCCESS,
  DISMISS_BROADCAST_MESSAGE_FAIL,
  DISMISS_BROADCAST_MESSAGE_SUCCESS,
  FETCH_BROADCAST_USERS_START,
  FETCH_BROADCAST_USERS_SUCCESS,
  FETCH_BROADCAST_USERS_FAIL,
  UPLOAD_NEW_DOCUMENT_FAIL,
  UPLOAD_NEW_DOCUMENT_START,
  UPLOAD_NEW_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FILE_FAIL,
  UPDATE_DOCUMENT_FILE_START,
  UPDATE_DOCUMENT_FILE_SUCCESS,
  GET_DOCUMENT_FILE_FAIL,
  GET_DOCUMENT_FILE_START,
  GET_DOCUMENT_FILE_SUCCESS,
  DELETE_DOCUMENT_FILE_FAIL,
  DELETE_DOCUMENT_FILE_START,
  DELETE_DOCUMENT_FILE_SUCCESS,
  GET_DOCUMENTS_BY_SITE_TYPE_FAIL,
  GET_DOCUMENTS_BY_SITE_TYPE_START,
  GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS,
  GET_ALL_DOCUMENT_METADATA_FAIL,
  GET_ALL_DOCUMENT_METADATA_START,
  GET_ALL_DOCUMENT_METADATA_SUCCESS,
  FETCH_CATEGORY_NAMES_START,
  FETCH_CATEGORY_NAMES_SUCCESS,
  FETCH_CATEGORY_NAMES_FAIL,
  FETCH_FOLDER_NAMES_START,
  FETCH_FOLDER_NAMES_SUCCESS,
  FETCH_FOLDER_NAMES_FAIL,
  FETCH_SITE_TYPE_NAMES_START,
  FETCH_SITE_TYPE_NAMES_SUCCESS,
  FETCH_SITE_TYPE_NAMES_FAIL,
} from './types';

/**
 * storeUser
 * Save the user information
 * @param {object} userInfo receives user info
 * @returns
 */
export const storeUser = (userInfo) => ({
  type: STORE_USER,
  user: userInfo,
});

/**
 * fetchUserPolicyStart
 * Start fetch user policy
 */
export const fetchUserPolicyStart = () => ({
  type: FETCH_USER_POLICY_START,
});

/**
 * fetchUserPolicy
 * Fetch user policy
 */
export const fetchUserPolicy = () => ({
  type: FETCH_USER_POLICY,
});

/**
 * setUserPolicy
 * Save the user policy
 * @param {object} policy receives policy data
 */
export const setUserPolicy = (policy) => ({
  type: SET_USER_POLICY,
  payload: policy,
});

/**
 * fetchUserPolicyFail
 * Save the error fail when fetch user policy
 * @param {object} error receives error from fetch
 */
export const fetchUserPolicyFail = (error) => ({
  type: FETCH_USER_POLICY_FAIL,
  payload: error,
});

/**
 * setTermsAndConditions
 * Update terms and conditions to user
 * @param {object} policy receives policy data
 */
export const setTermsAndConditions = (policy) => ({
  type: UPDATE_TERMS_AND_CONDITIONS,
  payload: policy,
});

/**
 * setTermsAndConditionsFail
 * Save the error fail when set terms and conditions to user
 * @param {*} error receives error from set terms
 */
export const setTermsAndConditionsFail = (error) => ({
  type: UPDATE_TERMS_AND_CONDITIONS_FAIL,
  payload: error,
});

/**
 * getUserPolicy
 * Find user policy to endpoint
 */
export const getUserPolicy = () => (dispatch) => {
  dispatch(fetchUserPolicyStart());
  const apiUrl = `${endpointFormatter('fetchUserPolicy')}`;
  dispatch(apiRequest(() => doGet(apiUrl), setUserPolicy, fetchUserPolicyFail, false));
};

/**
 * updateUserPolicy
 *
 */
export const updateUserPolicy = (effectiveDate) => (dispatch) => {
  const apiUrl = `${endpointFormatter('fetchUserPolicy')}`;
  const onSuccess = () => setTermsAndConditions(effectiveDate);
  dispatch(apiRequest(() => doPut(apiUrl), onSuccess, setTermsAndConditionsFail, false));
};

/**
 * @method postUserSessionLoginEvent
 * Submit the request to register login events.
 * @param {Object} params Includes the event parameters to register.
 * @return {void}
 */
export const postUserSessionLoginEvent = () => (dispatch) => {
  const eventRegister = 'login';

  const apiUrl = `${endpointFormatter('userSessionEvents', {
    event: eventRegister,
  })}`;
  const onFail = (event) => {
    // eslint-disable-next-line
    envVars.doLog && console.log('userSessionEvent', event);
  };
  dispatch(apiRequest(() => doPost(apiUrl), null, onFail, false));
};

/**
 * @method postUserSessionLogoutEvent
 * Submit the request to register Logout events.
 * @param {Object} params Includes the event parameters to register.
 * @return {void}
 */
export const postUserSessionLogoutEvent = () => (dispatch) => {
  const eventRegister = 'logout';
  const apiUrl = `${endpointFormatter('userSessionEvents', {
    event: eventRegister,
  })}`;
  const onFail = (event) => {
    // eslint-disable-next-line
    envVars.doLog && console.log('userSessionEvent', event);
  };
  dispatch(apiRequest(() => doPost(apiUrl), null, onFail, false));
};

/**
 * @method setUpdateOktaProfileFail
 * Sets the error message in the reducer
 * so we can show the alert to let the user know what is wrong with the request.
 * @param error {Object} The error received from the failed API request.
 * @return {Object}
 */
export const setUpdateOktaProfileFail = (error) => {
  const errorResponse = getValueIfExists(() => error.response, {});
  const passwordText = 'password';
  const mobilePhoneText = 'mobilePhone';
  const exceptionsText = `Messages Exceptions`;

  let errorMessage = {
    type: '',
    message: '',
  };

  const responseMessage = getValueIfExists(
    () => errorResponse.data.errors[`${exceptionsText}`],
    [],
  )[0];

  if (responseMessage) {
    const responseType = responseMessage.toLowerCase().includes('password')
      ? passwordText
      : mobilePhoneText;
    const message = responseMessage.replace(`${responseType}: `, '');
    errorMessage = {
      type: responseType,
      message,
    };
  }

  return {
    type: UPDATE_USER_PROFILE_OKTA_FAIL,
    payload: errorMessage,
  };
};

/**
 * @method userOktaProfileDataSuccess
 * Updates user's mobile phone number on the store
 * @param {Object} payload Response from BE with the information
 * @return {Object}
 */
export const userOktaProfileDataSuccess = (payload) => ({
  type: UPDATE_USER_PROFILE_OKTA,
  payload,
});

/**
 * @method setUserOktaPasswordState
 * Save the state to okta password property on redux
 * @param {object} payload receives payload data
 */
export const setUserOktaPasswordState = (payload) => ({
  type: UPDATE_USER_PASSWORD_OKTA,
  payload,
});

/**
 * @method cleanUserOktaPasswordMessages
 * Clean state properties from message and password on user redux
 */
export const cleanUserOktaPasswordMessages = () => ({
  type: CLEAN_OKTA_MESSAGES_PASSWORD,
});

/**
 * @method setUserRoleConfig
 * Store the high-level role into user redux
 */
export const setUserRoleConfig = (payload) => ({
  type: SET_USER_ROLE_CONFIG,
  payload: getValueIfExists(() => payload.role, ''),
});

/**
 * @method setUserRoleConfigFail
 * Store the first role found into user redux
 */
export const setUserRoleConfigFail = (user) => {
  const userRoles = getValueIfExists(() => user.roles, []);
  const role = userRoles[userRoles.length - 1] || null;
  return {
    type: SET_USER_ROLE_CONFIG_FAIL,
    payload: role,
  };
};

/**
 * @method setUserAppConfig
 * Store the app user configuration allow-write-back-access
 */
export const setUserAppConfig = (payload) => ({
  type: SET_USER_APP_CONFIG,
  payload,
});

/**
 * @method setUserPermissionsStart
 * Save the user permissions in redux
 * @param {object} payload receives action.payload data
 */
export const setUserPermissions = (payload) => ({
  type: SET_USER_PERMISSIONS,
  payload,
});

/**
 * @method updateUserOktaProfileData
 * Request to update the user data in okta profile.
 * @param userData {Object} The user data to update.
 * @return {void}
 */
export const updateUserOktaProfileData = (userData) => (dispatch) => {
  let params;
  const password = getValueIfExists(() => userData.credentials.password, {});
  const mobilePhone = getValueIfExists(() => userData.profile.mobilePhone, null);
  if (Object.keys(password).length > 0) {
    params = password;
  }

  if (mobilePhone !== null) {
    params = {
      profile: {
        mobilePhone,
      },
    };
  }
  const apiUrl = `${endpointFormatter('updateUserOktaProfile')}`;

  const onSuccess = () =>
    Object.keys(password).length > 0
      ? setUserOktaPasswordState(true)
      : userOktaProfileDataSuccess(mobilePhone);

  dispatch(
    apiRequest(
      () => doPost(apiUrl, params),
      onSuccess,
      setUpdateOktaProfileFail,
      true,
      false,
      bannerHide.oktaUserUpdate,
    ),
  );
};

/**
 * fetchBroadcastUsersStart
 * Start fetching broadcast users
 */
export const fetchBroadcastUsersStart = () => ({
  type: FETCH_BROADCAST_USERS_START,
});

/**
 * fetchBroadcastUsersSuccess
 * Successfully fetched broadcast users
 * @param {array} users Array of broadcast users
 */
export const fetchBroadcastUsersSuccess = (users) => ({
  type: FETCH_BROADCAST_USERS_SUCCESS,
  payload: users,
});

/**
 * fetchBroadcastUsersFail
 * Failed to fetch broadcast users
 * @param {object} error Error object
 */
export const fetchBroadcastUsersFail = (error) => ({
  type: FETCH_BROADCAST_USERS_FAIL,
  payload: error,
});

/**
 * fetchBroadcastUsers
 * Fetches the list of broadcast users from the API
 */
export const fetchBroadcastUsers = () => (dispatch) => {
  dispatch(fetchBroadcastUsersStart());
  const apiUrl = `${endpointFormatter('fetchBroadcastUsers')}`;
  dispatch(
    apiRequest(() => doGet(apiUrl), fetchBroadcastUsersSuccess, fetchBroadcastUsersFail, false),
  );
};

/**
 * @param {array} save the success message when updating the broadcast message
 */
export const updateBroadcastMessageSuccess = (message) => ({
  type: UPDATE_BROADCAST_MESSAGE_SUCCESS,
  payload: message,
});

/**
 * Save the new broadcast message
 * @param {object} data is the message and list of users to notify
 */
export const updateBroadcastMessageStart = (broadcastMessage) => ({
  type: UPDATE_BROADCAST_MESSAGE_START,
  payload: broadcastMessage,
});

/**
 * Save the error attempting to update the broadcast message fails
 * @param {object} error receives error data
 */
export const updateBroadcastMessageFail = (error) => ({
  type: UPDATE_BROADCAST_MESSAGE_FAIL,
  payload: error,
});

/**
 * @method updateBroadcastMessage
 * Request to update broadcast message
 * @param {object} data is the message and list of users to notify
 */
export const updateBroadcastMessage = (data) => (dispatch) => {
  dispatch(updateBroadcastMessageStart());
  const paramsData = {
    ...data,
  };
  const apiUrl = `${endpointFormatter('updateBroadcastMessage', paramsData)}`;
  const onSuccess = () => updateBroadcastMessageSuccess(data);

  dispatch(apiRequest(() => doPut(apiUrl, paramsData), onSuccess, updateBroadcastMessageFail));
};

/**
 * @method getUserRoleConfig
 * Retrieves the high-level role from the user profile
 * @return {void}
 */
export const getUserRoleConfig = (user) => (dispatch) => {
  const apiUrl = `${endpointFormatter('fetchUserRoleConfig')}`;
  dispatch(apiRequest(() => doGet(apiUrl), setUserRoleConfig, setUserRoleConfigFail(user)));
};

export const getUserAppConfig = (email) => (dispatch) => {
  const apiUrl = `${endpointFormatter('fetchUserAppConfig')}`;
  dispatch(apiRequest(() => doPost(apiUrl, { email }), setUserAppConfig, null));
};

/**
 * @method setDismissBroadcastMessage
 * Store the high-level role into user redux
 */
export const setDismissBroadcastMessageSuccess = (payload) => ({
  type: DISMISS_BROADCAST_MESSAGE_SUCCESS,
  payload: getValueIfExists(() => payload.role, ''),
});

/**
 * @method setDismissBroadcastMessageFail
 */
export const setDismissBroadcastMessageFail = (error) => {
  return {
    type: DISMISS_BROADCAST_MESSAGE_FAIL,
    payload: error,
  };
};

/**
 * Dismisses the broadcast message for the given email.
 *
 * @param {string} email The email of the user dismissing the broadcast.
 * @returns {object} An action object with a type and payload.
 */
export const dismissBroadcastMessage = (email) => (dispatch) => {
  const apiUrl = `${endpointFormatter('dismissBroadcastMessage')}`;

  dispatch(
    apiRequest(
      () => doPost(apiUrl, { email }),
      setDismissBroadcastMessageSuccess,
      setDismissBroadcastMessageFail(email),
    ),
  );
};

// Create Document Category
export const uploadNewDocumentStart = () => ({
  type: UPLOAD_NEW_DOCUMENT_START,
});

export const uploadNewDocumentSuccess = (category) => ({
  type: UPLOAD_NEW_DOCUMENT_SUCCESS,
  payload: category,
});

export const uploadNewDocumentFail = (error) => ({
  type: UPLOAD_NEW_DOCUMENT_FAIL,
  payload: error,
});

export const uploadNewDocument = (category, formData) => (dispatch) => {
  const params = {
    category,
  };

  dispatch(uploadNewDocumentStart());
  const apiUrl = `${endpointFormatter('uploadNewDocument', params)}`;
  dispatch(
    apiRequest(
      () => doPost(apiUrl, formData),
      () => uploadNewDocumentSuccess(category),
      uploadNewDocumentFail,
    ),
  );
};

// Update Document File
export const updateDocumentFileStart = () => ({
  type: UPDATE_DOCUMENT_FILE_START,
});

export const updateDocumentFileSuccess = (fileData) => ({
  type: UPDATE_DOCUMENT_FILE_SUCCESS,
  payload: fileData,
});

export const updateDocumentFileFail = (error) => ({
  type: UPDATE_DOCUMENT_FILE_FAIL,
  payload: error,
});

export const updateDocumentFile = (category, fileData) => (dispatch) => {
  dispatch(updateDocumentFileStart());
  const apiUrl = `${endpointFormatter('updateDocumentFile', { category })}`;
  dispatch(
    apiRequest(
      () =>
        doPut(apiUrl, fileData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      () => updateDocumentFileSuccess({ category, ...fileData }),
      updateDocumentFileFail,
    ),
  );
};

// Get Single File
export const getDocumentFileStart = () => ({
  type: GET_DOCUMENT_FILE_START,
});

export const getDocumentFileSuccess = (fileData) => ({
  type: GET_DOCUMENT_FILE_SUCCESS,
  payload: fileData,
});

export const getDocumentFileFail = (error) => ({
  type: GET_DOCUMENT_FILE_FAIL,
  payload: error,
});

export const getDocumentFile = (category, filename) => (dispatch) => {
  dispatch(getDocumentFileStart());
  const apiUrl = `${endpointFormatter('getDocumentFile', { category, filename })}`;

  return new Promise((resolve, reject) => {
    dispatch(
      apiRequest(
        () => doGet(apiUrl),
        (successResponse) => {
          dispatch(getDocumentFileSuccess(successResponse));
          resolve(successResponse);
        },
        (error) => {
          dispatch(getDocumentFileFail(error));
          reject(error);
        },
      ),
    );
  });
};

// Delete Document File
export const deleteDocumentFileStart = () => ({
  type: DELETE_DOCUMENT_FILE_START,
});

export const deleteDocumentFileSuccess = (category, filename) => ({
  type: DELETE_DOCUMENT_FILE_SUCCESS,
  payload: { category, filename },
});

export const deleteDocumentFileFail = (error) => ({
  type: DELETE_DOCUMENT_FILE_FAIL,
  payload: error,
});

export const deleteDocumentFile = (category, filename) => (dispatch) => {
  dispatch(deleteDocumentFileStart());
  const apiUrl = `${endpointFormatter('deleteDocumentFile', { category, filename })}`;
  dispatch(
    apiRequest(
      () => doDelete(apiUrl),
      () => deleteDocumentFileSuccess(category, filename),
      deleteDocumentFileFail,
    ),
  );
};

// Get All by Site Type
export const getDocumentsBySiteTypeStart = () => ({
  type: GET_DOCUMENTS_BY_SITE_TYPE_START,
});

export const getDocumentsBySiteTypeSuccess = (documents) => ({
  type: GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS,
  payload: documents,
});

export const getDocumentsBySiteTypeFail = (error) => ({
  type: GET_DOCUMENTS_BY_SITE_TYPE_FAIL,
  payload: error,
});

export const getDocumentsBySiteType = (siteTypeName) => (dispatch) => {
  dispatch(getDocumentsBySiteTypeStart());
  const apiUrl = `${endpointFormatter('getDocumentsBySiteType', { siteTypeName })}`;
  dispatch(
    apiRequest(() => doGet(apiUrl), getDocumentsBySiteTypeSuccess, getDocumentsBySiteTypeFail),
  );
};

export const getAllDocumentMetadataStart = () => ({
  type: GET_ALL_DOCUMENT_METADATA_START,
});

export const getAllDocumentMetadataSuccess = (documents) => ({
  type: GET_ALL_DOCUMENT_METADATA_SUCCESS,
  payload: documents,
});

export const getAllDocumentMetadataFail = (error) => ({
  type: GET_ALL_DOCUMENT_METADATA_FAIL,
  payload: error,
});

export const getAllDocumentMetadata = () => (dispatch) => {
  dispatch(getAllDocumentMetadataStart());
  const apiUrl = `${endpointFormatter('getAllDocumentMetadata')}`;
  const onSuccess = (documents) => {
    dispatch(getAllDocumentMetadataSuccess(documents));
  };
  dispatch(apiRequest(() => doGet(apiUrl), onSuccess, getAllDocumentMetadataFail));
};

// Fetch Category Names Actions
export const fetchCategoryNamesStart = () => ({ type: FETCH_CATEGORY_NAMES_START });
export const fetchCategoryNamesSuccess = (categoryNames) => ({
  type: FETCH_CATEGORY_NAMES_SUCCESS,
  payload: categoryNames,
});
export const fetchCategoryNamesFail = (error) => ({
  type: FETCH_CATEGORY_NAMES_FAIL,
  payload: error,
});

export const fetchCategoryNames = () => (dispatch) => {
  dispatch(fetchCategoryNamesStart());
  const apiUrl = `${endpointFormatter('fetchCategoryNames')}`;
  dispatch(apiRequest(() => doGet(apiUrl), fetchCategoryNamesSuccess, fetchCategoryNamesFail));
};

// Folder Names Actions
export const fetchFolderNamesStart = () => ({ type: FETCH_FOLDER_NAMES_START });
export const fetchFolderNamesSuccess = (folderNames) => ({
  type: FETCH_FOLDER_NAMES_SUCCESS,
  payload: folderNames,
});
export const fetchFolderNamesFail = (error) => ({
  type: FETCH_FOLDER_NAMES_FAIL,
  payload: error,
});

export const fetchFolderNames = () => (dispatch) => {
  dispatch(fetchFolderNamesStart());
  const apiUrl = `${endpointFormatter('fetchFolderNames')}`;
  dispatch(apiRequest(() => doGet(apiUrl), fetchFolderNamesSuccess, fetchFolderNamesFail));
};

// Site Type Names Actions
export const fetchSiteTypeNamesStart = () => ({ type: FETCH_SITE_TYPE_NAMES_START });
export const fetchSiteTypeNamesSuccess = (siteTypeNames) => ({
  type: FETCH_SITE_TYPE_NAMES_SUCCESS,
  payload: siteTypeNames,
});
export const fetchSiteTypeNamesFail = (error) => ({
  type: FETCH_SITE_TYPE_NAMES_FAIL,
  payload: error,
});

export const fetchSiteTypeNames = () => (dispatch) => {
  dispatch(fetchSiteTypeNamesStart());
  const apiUrl = `${endpointFormatter('fetchSiteTypeNames')}`;
  dispatch(apiRequest(() => doGet(apiUrl), fetchSiteTypeNamesSuccess, fetchSiteTypeNamesFail));
};
