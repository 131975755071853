/**
 * Settings constants
 */
export const FETCH_RESOURCES_FOLDERS_START = '[Resources] FETCH_RESOURCES_FOLDERS_START';
export const FETCH_RESOURCES_FOLDERS_FAIL = '[Resources] FETCH_RESOURCES_FOLDERS_FAIL';
export const SET_RESOURCES_FOLDERS = '[Resources] SET_RESOURCES_FOLDERS';
export const FETCH_RESOURCES_ITEMS_START = '[Resources] FETCH_RESOURCES_ITEMS_START';
export const FETCH_RESOURCES_ITEMS_FAIL = '[Resources] FETCH_RESOURCES_ITEMS_FAIL';
export const SET_RESOURCES_ITEMS = '[Resources] SET_RESOURCES_ITEMS';
export const FETCH_RESOURCES_ITEM_URL_START = '[Resources] FETCH_RESOURCES_ITEM_URL_START';
export const FETCH_RESOURCES_ITEM_URL_FAIL = '[Resources] FETCH_RESOURCES_ITEM_URL_FAIL';
export const SET_RESOURCES_ITEM_URL = '[Resources] SET_RESOURCES_ITEM_URL';
