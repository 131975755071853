import { createTheme } from '@mui/material/styles';

export const Colors = {
  // Base
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  red: '#ff0000',
  green: '#00ff00',
  blue: '#0000ff',
  // Black/Gray shades
  codGray: '#191919',
  dustyGray: '#959595',
  athensGray: '#f3f6f8',
  dustGray: '#999999',
  haze: '#edf0f3',
  silver: '#bfbfbf',
  mercury: '#d7dde3',
  // Red shades
  paarl: '#a65628',
  cancan: '#da96a6',
  cadillac: '#af4a74',
  christine: '#f36f13',
  thunderbird: '#c13017',
  alizarinCrimson: '#db544a',
  buccaneer: '#5e2727',
  // Green shades
  jaggeIce: '#d0eedd',
  surfCrest: '#c8e4c8',
  mountainMeadow: '#20c161',
  olivine: '#23df78',
  eucalyptus: '#299d5e',
  finlandia: '#5a6b5a',
  lunarGreen: '#373a37',
  // Blue shades
  tiber: '#062c3f',
  havelockBlue: '#6383dd',
  pacificBlue: '#0b94bc',
  tarawera: '#063951',
  // Yellow shades
  hokeyPok: '#d8b031',
  goldenDream: '#ebcb39',
  peachYellow: '#faeaa3', // 'Sandwisp' on Zeplin
  // Not on Zeplin Palette
  primaryLight: '#62d390',
  primaryDark: '#179048',
  secondaryLight: '#d2f3df',
  secondaryDark: '#a6e6c0',
  alphaWhite: '#fbfbfb',
  rgbaGray87: 'rgba(0, 0, 0, 0.87)',
  rgbaGray12: 'rgba(0, 0, 0, 0.12)',
  affair: '#984ea3',
  bostonBlue: '#377eb8',
  aquaSqueeze: '#e8f6f4',
  goldenFizz: '#ffff33',
  goldenSand: '#f2d974',
  geyser: '#d6dde4',
  bamboo: '#db6400',
  flushOrange: '#ff7f00',
  fern: '#61b15a',
  persianPink: '#f781bf',
  wildSand: '#f6f6f6',
  stJohn: '#285469',
  lunarGreenAlpha10: '#d6e2e1',
  apple: '#43b542',
  edward: '#abaeae',
  melanie: '#d5abbc',
  rollingStone: '#737578',
  silverSand: '#b4bbbd',
  lynch: '#647f99',
  flameaPea: '#e02020',
  nandor: '#515e51',
};

export const FaithTheme = createTheme({
  palette: {
    primary: {
      light: Colors.primaryLight,
      main: Colors.mountainMeadow,
      dark: Colors.primaryDark,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.alizarinCrimson,
    },
    neutral: {
      main: Colors.lunarGreen,
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 16,
    fontFamily: 'Inter, sans-serif !important',
    h1: {
      fontSize: '2.4rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.1px',
    },
    body1: {
      fontSize: '1.6rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '-0.1px',
    },
    caption: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.1px',
      color: Colors.lunarGreen,
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.08px',
      color: Colors.mountainMeadow,
    },
    small: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    fontWeightBold: 600,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: Colors.haze,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          padding: '0',
        },
        containedPrimary: {
          height: 'auto',
          minHeight: '4rem',
          minWidth: '16rem',
          padding: '0',
          '&:hover': {
            backgroundColor: Colors.primaryLight,
          },
          '&:active': {
            backgroundColor: Colors.primaryDark,
          },
          '&: disabled': {
            opacity: 0.5,
            color: Colors.white,
            backgroundColor: Colors.primaryLight,
          },
        },
        outlinedPrimary: {
          height: 'auto',
          minHeight: '4rem',
          minWidth: '16rem',
          padding: '0',
          '&:focus': {
            backgroundColor: Colors.secondaryLight,
          },
          '&:hover': {
            backgroundColor: Colors.secondaryLight,
          },
          '&:active': {
            backgroundColor: Colors.secondaryDark,
          },
          '&: disabled': {
            opacity: 0.2,
          },
        },
        label: {
          padding: '0rem 0.8rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1.2rem 1.6rem 1.6rem 1.6rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
        button: {
          color: Colors.mountainMeadow,
          textDecoration: 'underline',
          '&:hover': {
            color: Colors.primaryLight,
          },
          '&:active': {
            color: Colors.primaryDark,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white,
          border: 0,
          boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.06)',
          marginBottom: '0.8rem',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: '0 0 0.8rem 0',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          marginBottom: -1,
          minHeight: '4.8rem',
          '&$expanded': {
            minHeight: '4.8rem',
          },
          border: 0,
          padding: '0 1.6rem 0 1.2rem',
        },
        content: {
          '&$expanded': {
            margin: '1.2rem 0',
            border: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0.8rem 1.4rem 1.6rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:not(.MuiOutlinedInput-multiline)': {
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: Colors.silver,
            },
            '&$disabled $notchedOutline': {
              borderColor: Colors.silver,
            },
          },
        },
        notchedOutline: {
          borderColor: Colors.mercury,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '2px',
        },
        adornedStart: {
          paddingLeft: '0.48rem',
        },
        input: {
          padding: '1.05rem 1.4rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.athensGray,
          '&$disabled': {
            backgroundColor: Colors.mercury,
          },
        },
        input: {
          padding: '0 1.2rem 0 0 ',
          color: Colors.lunarGreen,
          fontSize: '1.4rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, sans-serif',
          fontFize: '1.4rem',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.14,
          letterSpacing: 'normal',
        },
        contained: {
          marginTop: '0.125',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: '4rem',
          minWidth: ' 0.125rem',
          color: Colors.surfCrest,
        },
        fixed: {
          height: '4rem',
          color: Colors.surfCrest,
        },
        indicator: {
          backgroundColor: Colors.mountainMeadow,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: Colors.surfCrest,
          '&$selected': {
            color: Colors.mountainMeadow,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: `${Colors.white} !important`,
          border: 0,
          padding: '0',
          width: '4.66rem',
          height: '4rem',
          fontSize: '1.4rem',
          borderRadius: 2,
          backgroundColor: `${Colors.silver} !important`,
          '&:hover': {
            backgroundColor: Colors.silver,
          },
          '&.Mui-selected': {
            color: Colors.white,
            backgroundColor: `${Colors.mountainMeadow} !important`,
            '&:hover': {
              backgroundColor: Colors.mountainMeadow,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          width: '62.4rem',
          maxWidth: '62.4rem',
        },
        paper: {
          margin: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2.4rem 3.2rem 1.6rem',
          fontFamily: 'Inter !important',
          fontSize: '2.4rem',
          fontWeight: 600,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '-0.12px',
          color: Colors.lunarGreen,
          borderBottom: '1px solid',
          borderBottomColor: Colors.mercury,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter !important',
          padding: '1.6rem 3.2rem',
          fontSize: '1.4rem',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '-0.08px',
          color: Colors.lunarGreen,
          minHeight: '8rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '2.4rem 3.2rem',
          fontFamily: 'Inter !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.silverSand,
          '&$checked': {
            color: Colors.mountainMeadow,
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: '2rem',
          height: '2rem',
          fontSize: '1rem',
          fontWeight: 'bold',
          letterSpacing: '-0.06px',
          textAlign: 'center',
          backgroundColor: Colors.mountainMeadow,
          marginLeft: '4px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&.MuiSelect-outlined': {
            paddingRight: '5.3rem',
          },
          '&.Mui-disabled': {
            backgroundColor: Colors.mercury,
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: '2rem',
          paddingRight: '1.2rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-outlined': {
            fontSize: '1.4rem',
            transform: 'translate(1.4rem, 1.2rem) scale(1) !important',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(1.4rem, -6px) scale(0.75) !important',
            },
          },
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
});
