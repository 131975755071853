import { styled } from '@mui/material/styles';
import { Document, Page } from 'react-pdf';

const FullWidthHeightDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const StyledDocument = styled(Document)({
  maxWidth: '70vw',
  height: 'auto',
});

const StyledPage = styled(Page)({
  filter: 'contrast(1.1) brightness(1.05) sharpen(0.5)',
});

export { FullWidthHeightDiv, StyledDocument, StyledPage };
