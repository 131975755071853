const enTranslationMessages = [];

const DEFAULT_LOCALE_LOCAL = 'en';

// prettier-ignore
const appLocalesOptions = [
  'en',
];

const formatTranslationMessagesLocal = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE_LOCAL
      ? formatTranslationMessagesLocal(DEFAULT_LOCALE_LOCAL, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[+key] && locale !== DEFAULT_LOCALE_LOCAL
        ? defaultFormattedMessages[+key]
        : messages[+key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessagesLocal = {
  en: formatTranslationMessagesLocal('en', enTranslationMessages),
};

export const appLocales = appLocalesOptions;
export const formatTranslationMessages = formatTranslationMessagesLocal;
export const translationMessages = translationMessagesLocal;
export const DEFAULT_LOCALE = DEFAULT_LOCALE_LOCAL;
