/**
 * Unit Measurement reducer
 */
import { propertyExist } from 'utils/propertyValidation';
import {
  SET_NOTIFICATIONS_SETTINGS,
  SET_NOTIFICATION_PREFERENCES,
  SET_UNIT_MEASUREMENT,
  SET_NOTIFICATION_PREFERENCES_FAIL,
  SET_SITE_NOTIFICATIONS,
  FETCH_SITE_NOTIFICATIONS_FAIL,
  PATCH_SITE_NOTIFICATIONS,
  PATCH_SITE_NOTIFICATIONS_FAIL,
} from './types';

const initialState = {
  notifications: {},
  sites: [],
};
/**
 * settingsReducer
 * Update the states from notifications redux
 * @param {Object} state receives new state
 * @param {Object} action receives action data
 * @return {Object}
 */
const settingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        notifications: action.payload,
        displayPlaceholder: false,
      };
    case SET_NOTIFICATION_PREFERENCES_FAIL:
      return {
        ...state,
        notifications: null,
        displayPlaceholder: true,
      };
    case SET_NOTIFICATION_PREFERENCES:
      if (propertyExist(() => state.notifications.notificationPreferences)) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            notificationPreferences: state.notifications.notificationPreferences.map(
              (notification) =>
                notification.notificationPreferenceId === action.payload.notificationPreferenceId
                  ? action.payload
                  : notification,
            ),
          },
        };
      }
      return state;
    case SET_UNIT_MEASUREMENT:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unitMeasurement: action.payload,
        },
      };
    case SET_SITE_NOTIFICATIONS:
      return {
        ...state,
        // eslint-disable-next-line func-names
        sites: action.payload.sort(function (a, b) {
          return a.siteName.localeCompare(b.siteName);
        }),
      };
    case FETCH_SITE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        sites: [],
      };
    case PATCH_SITE_NOTIFICATIONS:
      return {
        ...state,
        sites: state.sites.map((site) =>
          site.siteId === action.payload[0].siteId ? action.payload[0] : site,
        ),
      };
    case PATCH_SITE_NOTIFICATIONS_FAIL:
      return state;
    default:
      return state;
  }
};

export default settingsReducer;
