/**
 * Site Types Catalog Object that mirrors the Information Model (Maitained by Faith)
 */
export const SiteTypes = {
  Ignition: 'Ignition',
  Xcape: 'XCape',
  acGrid: 'AC-Grid',
};

/**
 * Site Types Id Catalog Object
 */
export const SiteTypesId = {
  Ignition: 1,
  Xcape: 2,
  acGrid: 3,
};
