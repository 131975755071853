import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import images from 'assets/images';
import { Colors } from 'theme';
import logoImage from '../../../../assets/images/logo-branding-lg.jpg';

const Root = styled(Grid)({
  height: '100vh',
  backgroundColor: Colors.white,
});

const ImageContainerColumn = styled(Grid)({
  backgroundImage: `url("${images.loginBackImage}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minWidth: '72rem',
  maxWidth: '56.25%',
});

const OktaWidgetContainerColumn = styled(Grid)({
  backgroundPosition: 'center',
  minWidth: '56rem',
  maxWidth: '43.75%',
  boxShadow: 'none',
});

const PaperDiv = styled('div')({
  margin: '8px auto 8px auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '63rem',
  position: 'relative',
});

const OktaSignInDiv = styled('div')({
  width: '100%',
  '& #okta-sign-in': {
    border: '0',
    fontSize: '1.4rem !important',
    marginTop: '10rem !important',
    minWidth: '30rem !important',
  },
  '#okta-sign-in .password-expired .auth-footer .goto': {
    float: 'left',
  },
  '& #okta-sign-in.auth-container .okta-form-title': {
    textAlign: 'left',
    fontSize: '2.4rem',
    color: Colors.lunarGreen,
    paddingTop: '5rem',
    paddingBottom: '2.2rem',
    height: '11.5rem !important',
    background: `url(${logoImage}) 34.5rem no-repeat !important`,
  },
  '& #okta-sign-in .social-auth-microsoft-button': {
    position: 'flex',
    borderRadius: '2px',
    borderColor: `${Colors.mountainMeadow}`,
    display: 'block',
    color: `${Colors.mountainMeadow} !important`, // text color important?
    textAlign: 'center !important',
    background: `url(${images.microsoftIcon}) 1.5rem no-repeat !important`,
    fontWeight: '600 !important',
    '&:hover': {
      textDecoration: 'none !important',
      backgroundColor: `${Colors.alphaWhite} !important`,
    },
  },
  '& #okta-sign-in.auth-container .button-primary': {
    borderColor: Colors.mountainMeadow,
    color: Colors.white,
    background: Colors.mountainMeadow,
    position: 'flex',
    display: 'block',
  },
  '& #okta-sign-in.auth-container .link:active, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited':
    {
      fontSize: '1.6rem',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-0.1px',
      textDecoration: 'underline',
      color: Colors.mountainMeadow,
    },
  '& #okta-sign-in.auth-container .o-form-head': {
    textAlign: 'left',
  },
  '& #okta-sign-in .o-form-head+.o-form-explain': {
    textAlign: 'left',
  },
  '& #okta-sign-in.auth-container .okta-sign-in-header': {
    display: 'none',
  },
  '& #okta-sign-in.auth-container': {
    paddingTop: '2rem',
    height: '80vh',
    minHeight: '65rem',
  },
  // Enables logo visibility
  '& #okta-sign-in.auth-container.main-container': {
    width: '100%',
    boxShadow: 'none',
  },
});

const LegalLinksContainer = styled(Grid)({
  position: 'relative',
  display: 'grid',
  bottom: '0',
  textAlign: 'center',
  width: '46.4rem',
  margin: '0 auto',
  gridTemplateColumns: 'repeat(2, 1fr)',
});

const TermsAndConditionsLink = styled(Link)({
  fontWeight: 'normal',
  display: 'flex',
  color: Colors.mountainMeadow,
  fontSize: '1.6rem',
  textAlign: 'center',
  float: 'left',
});

const PrivacyPolicyLink = styled(Link)({
  fontWeight: 'normal',
  display: 'flex',
  color: Colors.mountainMeadow,
  fontSize: '1.6rem',
  textAlign: 'center',
  float: 'right',
});

export {
  Root,
  ImageContainerColumn,
  OktaWidgetContainerColumn,
  PaperDiv,
  OktaSignInDiv,
  LegalLinksContainer,
  TermsAndConditionsLink,
  PrivacyPolicyLink,
};
