/**
 * index.js
 *
 * App component
 */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { FaithTheme } from '../../theme';
import AppWithRouterAccess from './AppWithRouterAccess';

/**
 * App Method is executed to load all the components in place
 * and run the application.
 * It also includes the routes declaration.
 */
function Root() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FaithTheme}>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <AppWithRouterAccess />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Root;
