import images from 'assets/images';

/**
 * Enum Site Event Names.
 */
export const resourcesFilterTypes = {
  All: 'All',
  Video: 'Videos',
  Guidebook: 'Guidebook',
  Other: 'Other',
};

export const resourcesFilterOptions = [
  {
    value: 1,
    text: resourcesFilterTypes.All,
  },
  {
    value: 2,
    text: resourcesFilterTypes.Video,
  },
  {
    value: 3,
    text: resourcesFilterTypes.Guidebook,
  },
  {
    value: 4,
    text: resourcesFilterTypes.Other,
  },
];

export const resourcesTypes = [
  {
    id: 1,
    name: 'Overview and Background',
    icon: images.iconOverviewAndBackground,
  },
  {
    id: 2,
    name: 'Customer Documentation',
    icon: images.iconCustomerDocumentation,
  },
  {
    id: 3,
    name: 'ICIAN Documentation',
    icon: images.iconIcianDocumentation,
  },
  {
    id: 4,
    name: 'Support Team Documentation',
    icon: images.iconSupportTeamDocumentation,
  },
];
