/**
 * Resources reducer
 */
import { resourcesFilterTypes } from 'utils/enums/resources';
import {
  FETCH_RESOURCES_FOLDERS_FAIL,
  SET_RESOURCES_FOLDERS,
  FETCH_RESOURCES_ITEMS_FAIL,
  SET_RESOURCES_ITEMS,
  FETCH_RESOURCES_ITEM_URL_FAIL,
  SET_RESOURCES_ITEM_URL,
} from './types';

/**
 * sortItems
 * Sorts the incomming items on an specific order
 * @param {Object[]} items list of items to sort
 * @returns {Object[]}
 */
export const sortItems = (items) => {
  const order = [
    resourcesFilterTypes.Video,
    resourcesFilterTypes.Guidebook,
    resourcesFilterTypes.Other,
  ];
  return items.sort((a, b) => order.indexOf(a.itemType) - order.indexOf(b.itemType));
};

const initialState = {
  folders: [],
  items: [],
  item: {},
};
/**
 * resourcesReducer
 * Update the states from resources redux
 * @param {Object} state receives new state
 * @param {Object} action receives action data
 * @return {Object}
 */
const resourcesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_RESOURCES_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case FETCH_RESOURCES_FOLDERS_FAIL:
    case FETCH_RESOURCES_ITEMS_FAIL:
      return {
        ...state,
      };
    case SET_RESOURCES_ITEMS:
      return {
        ...state,
        items: sortItems(action.payload),
      };
    case SET_RESOURCES_ITEM_URL:
      return {
        ...state,
        item: action.payload,
      };
    case FETCH_RESOURCES_ITEM_URL_FAIL:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

export default resourcesReducer;
