import { propertyExist } from 'utils/propertyValidation';
import bannerHide from 'utils/enums/bannerHide';
import API_REQUEST from '../../api/types';
import { hideLoader, showLoader, showToast, showBanner } from '../../App/actions';

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    if (type !== API_REQUEST) {
      return;
    }

    const { request, onSuccess, onFail, showAppLoading, showToastNotification, requestType } =
      action.payload;

    if (showAppLoading) {
      dispatch(showLoader());
    }

    request()
      .then((data) => {
        if (onSuccess) {
          const onSuccessResult = onSuccess(data);
          if (propertyExist(() => onSuccessResult.type)) {
            dispatch(onSuccessResult);
          }
        }
        if (showToastNotification) {
          dispatch(showToast('success'));
        }
      })
      .catch((error) => {
        const onFailResult = onFail(error);
        if (propertyExist(() => onFailResult.type)) {
          dispatch(onFailResult);
        }
        if (showToastNotification) {
          dispatch(showToast('error'));
        }
        if (error.code !== 'ERR_CANCELED') {
          const notShowBanner = Object.values(bannerHide).findIndex(
            (value) => value === requestType,
          );
          if (notShowBanner < 0) {
            dispatch(showBanner(requestType, error?.response?.status));
          }
        }
      })
      .finally(() => {
        if (showAppLoading) {
          dispatch(hideLoader());
        }
      });
  };

export default apiMiddleware;
